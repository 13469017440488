<template>
  <div style="margin-top: 1rem;">
    <b-button-toolbar
      key-nav
      aria-label="Toolbar with button groups"
      class="demo-inline-spacing"
    >
      <b-button-group
        size="sm"
        class="cbs-inline-spacing mb-1"
      >
        <b-button
          title="Создать новую заявку"
          :variant="hasEmptyRecord() ? 'outline-primary' : 'primary'" :disabled="hasEmptyRecord()"
          @click="createRequest()"
        >
          <feather-icon icon="PlusIcon" />&nbsp;Создать
        </b-button>
      </b-button-group>
      <b-button-group
        size="sm"
        class="cbs-inline-spacing mb-1"
      >
        <b-button
          title="Удалить заявку"
          :disabled="selected().length === 0"
          :variant="selected().length === 0 ? 'outline-danger' : 'danger'"
          @click="deleteRecords()"
        >
          <feather-icon icon="TrashIcon" />
          {{ t('Удалить') }} ({{ selected().length }})
        </b-button>
      </b-button-group>
    </b-button-toolbar>

    <cbs-collapse :trigger="dataReady" style="overflow: scroll; max-height: 70vh; min-height: 20rem;">
      <table v-if="dataReady" class="table table-bordered">
        <thead>
          <tr>
            <th :id="`firstcol_${uuid}`" class="stick-th1">ID</th>
            <th class="stick-th">Вид ТРУ</th>
            <th :id="`secondcol_${uuid}`" class="stick-th2 stick-two">Наименование ТРУ</th>
            <th class="stick-th">Раздел Бюджета</th>
            <th class="stick-th">Статья Бюджета</th>
            <th v-if="false" class="stick-th">Статья КВ</th>
            <th class="stick-th">Ед изм</th>
            <th class="stick-th">Количество</th>
            <th class="stick-th">Валюта</th>
            <th class="stick-th">Цена</th>
            <th class="stick-th">Период инициации</th>
            <th class="stick-th">Тип закупа</th>
            <th class="stick-th">Условия оплаты</th>
            <th class="stick-th">НДС</th>
            <th class="stick-th">Контрагент</th>
            <th class="stick-th">Подразделение</th>
            <th class="stick-th">Автор</th>
            <th class="stick-th">Создано</th>
            <th class="stick-th2 stick-three">Сумма</th>
            <th class="stick-th" v-for="(mn, idx) in monthnames" :key="'mn_' + idx">{{ mn + '-' + (baseYear - 2000) }}</th>
            <th class="stick-th" v-for="(yr, idx) in years" :key="'yr_' + idx">{{ baseYear + yr }}</th>
          </tr>
          <tr>
            <th class="flt0 flt flt1">
              <cbs-budreq-filter :column="getColumn('id')" :condition="getCondition('id')" @apply="applyFilter()" />
            </th>
            <th class="flt0 flt2" :style="styleFlt3()">
              <cbs-budreq-filter :column="getColumn(keyPurchaseType)" :condition="getCondition(keyPurchaseType)" :reference="refs['purchase_type']" @apply="applyFilter()" />
            </th>
            <th class="flt" :style="styleFlt2()">
              <cbs-budreq-filter :column="getColumn(keyInventoryName)" :condition="getCondition(keyInventoryName)" @apply="applyFilter()" />
            </th>
            <th class="flt0 flt2" :style="styleFlt3()">
              <cbs-budreq-filter :column="getColumn(keyBudgetSection)" :condition="getCondition(keyBudgetSection)" :reference="refs['budget_section']" @apply="applyFilter()" />
            </th>
            <th class="flt0 flt2" :style="styleFlt3()">
              <cbs-budreq-filter :column="getColumn(keyPnl)" :condition="getCondition(keyPnl)" :reference="refs['pnlitem']" @apply="applyFilter()" />
            </th>
            <th v-if="false" class="flt0 flt2" :style="styleFlt3()">
              <cbs-budreq-filter :column="getColumn(keyCapex)" :condition="getCondition(keyCapex)" :reference="refs['capex_item']" @apply="applyFilter()" />
            </th>
            <th class="flt0 flt2" :style="styleFlt3()">
              <cbs-budreq-filter :column="getColumn(keyMetric)" :condition="getCondition(keyMetric)" :reference="refs['metric']" @apply="applyFilter()" />
            </th>
            <th class="flt2" :style="styleFlt3()"></th>
            <th class="flt0 flt2" :style="styleFlt3()">
              <cbs-budreq-filter :column="getColumn(keyCurrency)" :condition="getCondition(keyCurrency)" :reference="refs['currency']" @apply="applyFilter()" />
            </th>
            <th class="flt2" :style="styleFlt3()"></th>
            <th class="flt2" :style="styleFlt3()"></th>
            <th class="flt0 flt2" :style="styleFlt3()">
              <cbs-budreq-filter :column="getColumn(keyPurchasePeriod)" :condition="getCondition(keyPurchasePeriod)" :reference="refs['purchase_period']" @apply="applyFilter()" />
            </th>
            <th class="flt2" :style="styleFlt3()"></th>
            <th class="flt2" :style="styleFlt3()"></th>
            <th class="flt0 flt2" :style="styleFlt3()">
              <cbs-budreq-filter :column="getColumn(keyFirm)" :condition="getCondition(keyFirm)" :reference="refs['firm']" @apply="applyFilter()" />
            </th>
            <th class="flt2" :style="styleFlt3()">
              <cbs-budreq-filter :column="getColumn(keyUnit)" :condition="getCondition(keyUnit)" :reference="refs['business_unit']" @apply="applyFilter()" />
            </th>
            <th class="flt0 flt2" :style="styleFlt3()">
              <cbs-budreq-filter :column="getColumn(keyAuthorSid)" :condition="getCondition(keyAuthorSid)" :reference="refs['authorsid']" @apply="applyFilter()" />
            </th>
            <th class="flt2" :style="styleFlt3()"></th>
            <th class="flt stick-three" :style="styleFlt4()"></th>
            <th class="flt2" :style="styleFlt3()" v-for="(mn, idx) in monthnames" :key="'mnf_' + idx"></th>
            <th class="flt2" :style="styleFlt3()" v-for="(yr, idx) in years" :key="'yrf_' + idx"></th>
          </tr>
        </thead>
        <tbody v-for="rec in qryRequest.recordset.records" :key="'rec_' + rec.id.value">
          <tr style="padding: 0;">
            <td rowspan="2" class="table-light stick-col1" style="padding: 0.5rem; text-align: center;"
                :style="rec.cbs_access === 'rw' ? 'cursor: pointer;' : ''"
                @click="selectRec(rec)">
              <feather-icon v-if="rec.cbs_access === 'rw'" :icon="rec.selected ? 'CheckSquareIcon' : 'SquareIcon'" />
              <feather-icon v-if="rec.cbs_access === 'ro'" icon="LockIcon" />
              {{ rec.id.value }}
            </td>
            <td rowspan="2" class="cell-val">
              <cbs-reference-picker3 :record="rec"
                                     :field="qryRequest.columns.find(c => c.key === keyPurchaseType)"
                                     :prop-ref="refs['purchase_type']" @updateCell="onUpdateCell(rec, keyPurchaseType, $event)"
                                     style="text-align: center;"
              />
            </td>
            <td rowspan="2" class="table-light cell-val stick-col stick-two">
              <cbs-budreq-cell align-left :record="rec" :key-value="keyInventoryName" @edit="onEdit(rec, keyInventoryName, $event)" />
            </td>
            <td rowspan="2" class="cell-val">
              <cbs-reference-picker3 :record="rec"
                                     :field="qryRequest.columns.find(c => c.key === keyBudgetSection)"
                                     :prop-ref="refs['budget_section']"
                                     @updateCell="onUpdateCell(rec, keyBudgetSection, $event)"
              />
            </td>
            <td rowspan="2" class="cell-val">
              <cbs-reference-picker3 v-if="budgetSectionSid(rec[keyBudgetSection].value) === 'capex'"
                                     :record="rec"
                                     :field="qryRequest.columns.find(c => c.key === keyCapex)"
                                     :prop-ref="refCapex(rec)"
                                     @updateCell="onUpdateCell(rec, keyCapex, $event)"
              />
              <cbs-reference-picker3 v-if="budgetSectionSid(rec[keyBudgetSection].value) !== 'capex'"
                                     :record="rec"
                                     :field="qryRequest.columns.find(c => c.key === keyPnl)"
                                     :prop-ref="refPnl(rec)"
                                     @updateCell="onUpdateCell(rec, keyPnl, $event)" />
            </td>
            <td v-if="false" rowspan="2" class="cell-val">
              <cbs-reference-picker3 v-if="budgetSectionSid(rec[keyBudgetSection].value) === 'capex'"
                                     :record="rec"
                                     :field="qryRequest.columns.find(c => c.key === keyCapex)"
                                     :prop-ref="refs['capex_item']"
                                     @updateCell="onUpdateCell(rec, keyCapex, $event)"
              />
            </td>
            <td rowspan="2" class="cell-val" style="text-align: center;">
              <cbs-reference-picker3 :record="rec"
                                     :field="qryRequest.columns.find(c => c.key === keyMetric)"
                                     :prop-ref="refs['metric']"
                                     @updateCell="onUpdateCell(rec, keyMetric, $event)" />
            </td>
            <td rowspan="2" class="cell-val">
              <cbs-budreq-cell :record="rec" :key-value="keyQuantity" value-type="number" @edit="onEdit(rec, keyQuantity, $event)" />
            </td>
            <td rowspan="2" class="cell-val" style="text-align: center;">
              <cbs-reference-picker3 :record="rec"
                                     :field="qryRequest.columns.find(c => c.key === keyCurrency)"
                                     :prop-ref="refs['currency']"
                                     @updateCell="onUpdateCell(rec, keyCurrency, $event)" />
            </td>
            <td rowspan="2" class="cell-val">
              <cbs-budreq-cell :record="rec" :key-value="keyPrice" value-type="number" @edit="onEdit(rec, keyPrice, $event)" />
            </td>
            <td rowspan="2">
              <cbs-budreq-cell :record="rec" :key-value="keyPurchaseDate" value-type="date" @edit="onEdit(rec, keyPurchaseDate, $event)" />
            </td>
            <td rowspan="2" class="cell-val" style="text-align: center;">
              <cbs-reference-picker3 :record="rec"
                                     :field="qryRequest.columns.find(c => c.key === keyPurchasePeriod)"
                                     :prop-ref="refs['purchase_period']"
                                     @updateCell="onUpdateCell(rec, keyPurchasePeriod, $event)" />
            </td>
            <td rowspan="2" class="cell-val">
              <cbs-budreq-cell :record="rec" :key-value="keyPaymentTerms" @edit="onEdit(rec, keyPaymentTerms, $event)" />
            </td>
            <td rowspan="2" class="cell-val" style="text-align: center;">
              <cbs-reference-picker3 :record="rec"
                                     :field="qryRequest.columns.find(c => c.entitysid === 'vat_item')"
                                     :prop-ref="refs['vat_item']"
                                     @updateCell="onUpdateCell(rec, keyVat, $event)" />
            </td>
            <td rowspan="2" class="cell-val" style="text-align: center;">
              <cbs-reference-picker3 :record="rec"
                                     :field="qryRequest.columns.find(c => c.key === keyFirm)"
                                     :prop-ref="refs['firm']"
                                     @updateCell="onUpdateCell(rec, keyFirm, $event)" />
            </td>
            <td rowspan="2" class="cell-val" style="text-align: center;">
              <cbs-reference-picker3 v-if="isAdmin()"
                                     :record="rec"
                                     :field="qryRequest.columns.find(c => c.key === keyUnit)"
                                     :prop-ref="refs['business_unit']"
                                     @updateCell="onUpdateCell(rec, keyUnit, $event)" />
              <span v-else>{{ rec[keyUnit].title }}</span>
            </td>
            <td rowspan="2" class="cell-val">{{ rec[keyAuthorSid].title }}</td>
            <td rowspan="2" class="cell-val" style="text-align: center;">{{ rec[keyCreated].value }}</td>
            <td class="table-light stick-three" style="padding: 0 0.5rem 0 0.5rem;" :style="'position: sticky; left: ' + secondColWidth() + 'px; z-index: 1;'">
              Начисление
            </td>
            <td v-for="mn in months" :key="'mn_' + mn" style="padding: 0 0.5rem 0 0.5rem;">
              <cbs-budreq-cell :record="getSubRecord(rec, subObjSidAccrual, 'month', mn)"
                               :key-value="keyAmountCur(subObjSidAccrual)"
                               value-type="number"
                               @edit="onEditSub(getSubRecord(rec, subObjSidAccrual, 'month', mn), subObjSidAccrual, $event)"
                               style="text-align: right;"
              />
            </td>
            <td v-for="yr in years" :key="'yr_' + yr" style="padding: 0 0.5rem 0 0.5rem;">
              <cbs-budreq-cell :record="getSubRecord(rec, subObjSidAccrual, 'year', yr)"
                               :key-value="keyAmountCur(subObjSidAccrual)"
                               value-type="number"
                               @edit="onEditSub(getSubRecord(rec, subObjSidAccrual, 'year', yr), subObjSidAccrual, $event)"
                               style="text-align: right;"
              />
            </td>
          </tr>
          <tr style="padding: 0 1rem 0 1rem;">
            <td class="table-light stick-three" style="padding: 0 0.5rem 0 0.5rem;" :style="'position: sticky; left: ' + secondColWidth() + 'px; z-index: 1;'">
              Оплата
            </td>
            <td v-for="mn in months" :key="'mn_' + mn" style="padding: 0 0.5rem 0 0.5rem;">
              <cbs-budreq-cell :record="getSubRecord(rec, subObjSidPayment, 'month', mn)"
                               :key-value="keyAmountCur(subObjSidPayment)"
                               value-type="number"
                               @edit="onEditSub(getSubRecord(rec, subObjSidPayment, 'month', mn), subObjSidPayment, $event)"
                               style="text-align: right;"
              />
            </td>
            <td v-for="yr in years" :key="'yr_' + yr" style="padding: 0 0.5rem 0 0.5rem;">
              <cbs-budreq-cell :record="getSubRecord(rec, subObjSidPayment, 'year', yr)"
                               :key-value="keyAmountCur(subObjSidPayment)"
                               value-type="number"
                               @edit="onEditSub(getSubRecord(rec, subObjSidPayment, 'year', yr), subObjSidPayment, $event)"
                                style="text-align: right;"
              />
            </td>
          </tr>
          <tr v-if="false && rec.expanded" style="margin: 0; padding: 0;">
            <td colspan="12" style="margin: 0; padding: 0;">
              <div style="margin-left: 1rem;">
                <cbs-collapse :trigger="rec.expanded">
                  <cbs-budreq-finance :budget-request="rec" />
                </cbs-collapse>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </cbs-collapse>

    <cbs-pagination v-if="qryRequest" :page-size="pageSize" :record-count="recordcount" :current-page="currentPage"
                    @page-change="onChangePageNumber"
    />

    <cbs-debug v-if="session.user.sid === 'root'" :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import useCubus from '@/cubus/services/useCubus'
import CbsCollapse from '@/cubus/components/collapsible/CbsCollapse.vue'
import CbsBudreqFinance from '@/cubus/components/cbs-form/custom-forms/budget-request/CbsBudreqFinance.vue'
import CbsPagination from '@/cubus/components/pagination/CbsPagination.vue'
import {
  BButton, BButtonGroup, BButtonToolbar, VBTooltip,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import CbsBudreqCell from '@/cubus/components/cbs-form/custom-forms/budget-request/CbsBudreqCell.vue'
import CbsReferencePicker3 from '@/cubus/components/reference/CbsReferencePicker3.vue'
import CbsBudreqFilter from '@/cubus/components/cbs-form/custom-forms/budget-request/CbsBudreqFilter.vue'

export default {
  name: 'CbsBudreqTable',
  components: {
    CbsBudreqFilter,
    CbsReferencePicker3,
    CbsBudreqCell,
    CbsPagination,
    CbsCollapse,
    CbsDebug,
    CbsBudreqFinance,
    BButtonToolbar,
    BButtonGroup,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    scenario: {
      type: Object,
      default: null,
    },
    keyScenario: {
      type: String,
      default: null,
    },
    keyBaseYear: {
      type: String,
      default: null,
    },
    qryAccess: {
      type: Object,
      default: null,
    },
    roValue: {
      type: Number,
      default: null,
    },
    rwValue: {
      type: Number,
      default: null,
    },
  },
  setup() {
    const { t } = useI18nUtils()
    const session = useCubus.getSession()
    const cfg = {
      objSid_budgetSection: 'budget_section',
      objSid_request: 'cube_budget_request',
      objSid_pnl: 'refpnl',
      objSid_capex: 'refcapex',
      entSid_access: 'indicatoraccess',
      entSid_budgetSection: 'budget_section',
      entSid_pnl: 'pnlitem',
      entSid_capex: 'capex_item',
      entSid_role: 'role',
      filter_notDel: {
        isactive: true,
        node: {
          isactive: true,
          oper: { sid: 'and' },
          nodes: [],
          conds: [
            {
              isactive: true,
              oper: { sid: 'equal' },
              args: [
                { type: { sid: 'entitysid' }, value: 'del' },
                { type: { sid: 'value' }, value: false },
              ],
            },
          ],
        },
      },
      orderby_code: {
        isActive: true,
        sorts: [
          {
            isActive: true,
            oper: { sid: 'asc' },
            entity: { sid: 'code' },
          },
        ],
      },
    }
    return {
      t, // i18n
      session,
      cfg,
    }
  },
  data() {
    return {
      uuid: null,
      // firstColunm: null,
      // secondColumn: null,
      objRequest: null,
      objBudgetSection: null,
      qryRequest: null,
      qryBudgetSection: null,
      keyPurchaseType: null,
      keyInventoryName: null,
      // keySupplierInventoryName: null,
      keyBudgetSection: null,
      keyPnl: null,
      keyCashflow: null,
      keyCapex: null,
      keyMetric: null,
      keyQuantity: null,
      keyCurrency: null,
      keyPrice: null,
      keyPurchaseDate: null,
      keyPurchasePeriod: null,
      keyPaymentTerms: null,
      keyAuthorSid: null,
      keyCreated: null,
      keyUnit: null,
      keyFirm: null,
      keyVat: null,
      pageSize: 10,
      currentPage: 1,
      recordcount: 0,
      obj: null,
      monthnames: ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'],
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      years: [1, 2, 3, 4],
      baseYear: null,
      refBudgetSection: null,
      refs: {},
      menuVisible: false,
      subObjSidAccrual: 'budget_request_accrual',
      subObjSidPayment: 'budget_request_payment',
      keyAccess: null,
      keyRole: null,
      filter: {
        isactive: true,
        node: {
          isactive: true,
          oper: { sid: 'and' },
          nodes: [
            {
              isactive: true,
              oper: { sid: 'and', name: 'AND' },
              nodes: [],
              conds: [],
            },
          ],
          conds: [
            {
              isactive: true,
              oper: { sid: 'equal' },
              args: [
                { type: { sid: 'entitysid' }, value: 'del' },
                { type: { sid: 'value' }, value: false },
              ],
            },
            {
              isactive: true,
              oper: { sid: 'equal' },
              args: [
                { type: { sid: 'entitysid' }, value: 'scenario' },
                { type: { sid: 'value' }, value: this.scenario[this.keyScenario].value },
              ],
            },
          ],
        },
      },
      dataReady: false,
    }
  },
  computed: {
    locale() {
      return useCubus.locale(this.$i18n.locale)
    },
  },
  created() {
    this.uuid = useCubus.guid()
    this.init()
  },
  methods: {
    init() {
      this.keyAccess = this.qryAccess.columns.find(c => c.entitysid === this.cfg.entSid_access).key
      this.keyRole = this.qryAccess.columns.find(c => c.entitysid === this.cfg.entSid_role).key
      this.baseYear = this.scenario[this.keyBaseYear].value
      this.loadObjBudgetSection()
    },
    async loadObjBudgetSection() {
      this.objBudgetSection = await useCubus.loadObject(this, { objectsid: this.cfg.objSid_budgetSection })
      const params = {
        keymode: 'sid',
        objectsid: this.cfg.objSid_budgetSection,
        filter: this.cfg.filter_notDel,
        orderby: this.cfg.orderby_code,
        pageSize: 100000,
      }
      this.qryBudgetSection = await useCubus.loadQuery(this, params)
      this.objRequest = await useCubus.loadObject(this, { objectsid: this.cfg.objSid_request })
      this.refBudgetSection = await this.loadRef(this.cfg.entSid_budgetSection)
      await this.loadQryRequest('init')
    },
    async loadQryRequest(caller) {
      const params = {
        withsubrecords: true,
        objectsid: this.cfg.objSid_request,
        filter: this.filter,
        pageSize: this.pageSize,
        offset: this.pageSize * (this.currentPage - 1),
      }
      this.qryRequest = await useCubus.loadQuery(this, params)
      if (this.qryRequest && caller === 'init') {
        this.initQryRequest()
        await this.initRefs()
        this.dataReady = true
        /*
        this.$nextTick(() => {
          this.firstColunm = document.getElementById(`firstcol_${this.uuid}`)
          this.secondColunm = document.getElementById(`secondcol_${this.uuid}`)
          this.stickSecondCol()
        })
        */
      }
      this.$nextTick(() => {
        this.firstColunm = document.getElementById(`firstcol_${this.uuid}`)
        this.secondColunm = document.getElementById(`secondcol_${this.uuid}`)
        this.stickSecondCol()
      })
      this.qryRequest.recordset.records.forEach(r => this.$set(r, 'cbs_access', this.accessSid(r)))
    },
    async loadRef(entitysid) {
      if (entitysid === this.cfg.entSid_budgetSection && this.qryBudgetSection) {
        this.initRefBudgetSection()
      } else if (entitysid === this.cfg.entSid_pnl) {
        this.initRefPnl()
      } else if (entitysid === this.cfg.entSid_capex) {
        this.initRefCapex()
      } else {
        const ref = await useCubus.loadRef(this, { entitysid })
        if (ref) {
          this.$set(this.refs, entitysid, ref)
        }
      }
    },
    initQryRequest() {
      const qry = this.qryRequest
      this.keyPurchaseType = qry.columns.find(c => c.entitysid === 'purchase_type').key
      this.keyInventoryName = qry.columns.find(c => c.entitysid === 'inventory_name').key
      this.keyBudgetSection = qry.columns.find(c => c.entitysid === 'budget_section').key
      this.keyPnl = qry.columns.find(c => c.entitysid === this.cfg.entSid_pnl).key
      this.keyCashflow = qry.columns.find(c => c.entitysid === 'cashflow_item').key
      this.keyCapex = qry.columns.find(c => c.entitysid === 'capex_item').key
      this.keyMetric = qry.columns.find(c => c.entitysid === 'metric').key
      this.keyQuantity = qry.columns.find(c => c.entitysid === 'qty').key
      this.keyCurrency = qry.columns.find(c => c.entitysid === 'currency').key
      this.keyPrice = qry.columns.find(c => c.entitysid === 'pricecur').key
      this.keyPurchaseDate = qry.columns.find(c => c.entitysid === 'purchase_date').key
      this.keyPurchasePeriod = qry.columns.find(c => c.entitysid === 'purchase_period').key
      this.keyPaymentTerms = qry.columns.find(c => c.entitysid === 'payment_terms').key
      this.keyAuthorSid = qry.columns.find(c => c.entitysid === 'authorsid').key
      this.keyCreated = qry.columns.find(c => c.entitysid === 'created').key
      this.keyUnit = qry.columns.find(c => c.entitysid === 'business_unit').key
      this.keyFirm = qry.columns.find(c => c.entitysid === 'firm').key
      this.keyVat = qry.columns.find(c => c.entitysid === 'vat_item').key
      this.recordcount = qry.recordcount
    },
    stickSecondCol() {
      const offset = document.getElementById(`firstcol_${this.uuid}`).offsetWidth
      const offset2 = document.getElementById(`secondcol_${this.uuid}`).offsetWidth
      const offset3 = document.getElementById(`firstcol_${this.uuid}`).offsetHeight
      // console.log('offset', offset)
      document.querySelectorAll('.stick-two').forEach(cell => {
        cell.style.left = `${offset}px`
      })
      document.querySelectorAll('.stick-three').forEach(cell => {
        cell.style.left = `${offset + offset2}px`
      })
      document.querySelectorAll('.flt0').forEach(cell => {
        cell.style.top = `${offset3}px`
      })
    },
    initRefs() {
      this.qryRequest.columns.filter(c => c.datatype === 'ref' || c.datatype === 'refsid').forEach(c => {
        this.loadRef(c.entitysid)
      })
    },
    initRefBudgetSection() {
      // console.log('initRefBudgetSection', this.qryBudgetSection)
      const ref = this.qryBudgetSection.recordset.records.map(rec => (
        {
          title: `${rec.code.value}. ${rec.name.value}`,
          value: rec.id.value,
          sid: rec.sid.value,
          disabled: rec.isfolder.value,
          code: rec.code.value,
        }
      ))
      if (ref) this.$set(this.refs, this.cfg.entSid_budgetSection, ref)
    },
    async initRefPnl() {
      const params = {
        objectsid: this.cfg.objSid_pnl,
        filter: this.cfg.filter_notDel,
        orderby: this.cfg.orderby_code,
        keymode: 'sid',
      }
      const qry = await useCubus.loadQuery(this, params)
      if (qry) {
        const ref = qry.recordset.records.map(rec => (
          {
            title: `${rec.code.value}. ${rec.name.value}`,
            value: rec.id.value,
            sid: rec.sid.value,
            disabled: rec.isfolder.value,
            code: rec.code.value,
            budget_section: rec.budget_section.value,
          }
        ))
        this.$set(this.refs, this.cfg.entSid_pnl, ref)
      }
    },
    async initRefCapex() {
      const params = {
        objectsid: this.cfg.objSid_capex,
        filter: this.cfg.filter_notDel,
        orderby: this.cfg.orderby_code,
        keymode: 'sid',
      }
      const qry = await useCubus.loadQuery(this, params)
      if (qry) {
        const ref = qry.recordset.records.map(rec => (
          {
            title: `${rec.code.value}. ${rec.name.value}`,
            value: rec.id.value,
            sid: rec.sid.value,
            disabled: rec.isfolder.value,
            code: rec.code.value,
            budget_section: rec.budget_section.value,
          }
        ))
        this.$set(this.refs, this.cfg.entSid_capex, ref)
      }
    },
    firstColWidth() {
      if (this.firstColunm) return this.firstColunm.offsetWidth
      return 0
    },
    secondColWidth() {
      if (this.secondColunm) return this.firstColunm.offsetWidth + this.secondColunm.offsetWidth
      return 0
    },
    filterRequest() {
      return {
        isactive: true,
        node: {
          isactive: true,
          oper: { sid: 'and' },
          nodes: [],
          conds: [
            {
              isactive: true,
              oper: { sid: 'equal' },
              args: [
                { type: { sid: 'entitysid' }, value: 'del' },
                { type: { sid: 'value' }, value: false },
              ],
            },
            {
              isactive: true,
              oper: { sid: 'equal' },
              args: [
                { type: { sid: 'entitysid' }, value: 'scenario' },
                { type: { sid: 'value' }, value: this.scenario[this.keyScenario].value },
              ],
            },
          ],
        },
      }
    },
    budgetSectionSid(id) {
      if (!id) return null
      const code = this.qryBudgetSection.recordset.records.find(r => r.id.value === id).code.value
      if (code.startsWith('3')) return 'capex'
      return 'pnl'
    },
    onChangePageNumber(page) {
      // console.log('onChangePageNumber', page)
      this.currentPage = page
      this.loadQryRequest('pager')
    },
    stick() {
      const c = document.getElementById(`firstcol_${this.uuid}`)
      return c ? c.offsetWidth : 0
    },
    stick2() {
      const c = document.getElementById(`secondcol_${this.uuid}`)
      return c ? this.stick() + c.offsetWidth : 0
    },
    stick_h1() {
      const c = document.getElementById(`firstcol_${this.uuid}`)
      return c ? c.offsetHeight : 0
    },
    getSubRecord(parentRec, subObjectSid, periodName, pariodNum) {
      const strDt = this.getStrDate(periodName, pariodNum)
      const keyDate = this.keyDate(subObjectSid)
      const subRec = parentRec.sub_recordsets[subObjectSid].records.find(r => r[keyDate].value.startsWith(strDt))
      if (subRec) return subRec
      const newRec = this.getNewSubRecord(parentRec.sid.value, subObjectSid, keyDate, strDt)
      parentRec.sub_recordsets[subObjectSid].records.push(newRec)
      return newRec
    },
    keyDate(subObjectSid) {
      const subColumns = this.qryRequest.subcolumns[subObjectSid]
      if (!subColumns) {
        // useCubus.toastError(this, `SubColumns for ${subObjectSid} not found`)
        return 'date'
      }
      const col = subColumns.columns.find(c => c.entitysid === 'date')
      if (col) return col.key
      return 'date'
    },
    keyAmountCur(subObjectSid) {
      const col = this.qryRequest.subcolumns[subObjectSid].columns.find(c => c.entitysid === 'curamount')
      if (col) return col.key
      return 'curamount'
    },
    getStrDate(periodName, periodNum) {
      if (periodName === 'month') return `01.${periodNum < 10 ? `0${periodNum}` : periodNum}.${this.baseYear}`
      return `01.01.${this.baseYear + periodNum}`
    },
    getNewSubRecord(parentRecSid, subObjectSid, keyDate, strDate) {
      const res = {}
      this.qryRequest.subcolumns[subObjectSid].columns.forEach(c => {
        res[c.key] = c.default
      })
      res.del.value = false
      res.ownersid.value = parentRecSid
      res[keyDate].value = strDate
      return JSON.parse(JSON.stringify(res))
    },
    subObject(sid) {
      return this.objRequest.children.find(c => c.object.sid === sid)
    },
    subColumns(sid) {
      return this.qryRequest.subcolumns[sid].columns
    },
    onEditSub(rec, subObjectSid, newValue) {
      rec[this.keyAmountCur(subObjectSid)].value = newValue
      rec[this.keyAmountCur(subObjectSid)].status = 'changed'
      this.saveSubRecord(this.subObject(subObjectSid), rec)
    },
    async saveSubRecord(subObject, record) {
      try {
        const saved = await useCubus.saveRecord(this, { objectid: subObject.object.id, record })
        this.afterSaveSub(record, saved, subObject)
      } catch (e) { console.error(e) }
    },
    afterSaveSub(initRec, savedRec, subObject) {
      savedRec.status = 'saved'
      this.subColumns(subObject.object.sid).forEach(fld => {
        if (fld.key !== 'actions') {
          initRec[fld.key].value = savedRec[fld.key].value
          initRec[fld.key].title = savedRec[fld.key].title
        }
      })
      initRec.status = 'saved'
    },
    onEdit(rec, key, newValue) {
      rec[key].value = newValue
      rec[key].status = 'changed'
      this.saveRecord(rec)
    },
    async saveRecord(record) {
      try {
        const saved = await useCubus.saveRecord(this, { objectid: this.objRequest.object.id, record })
        this.afterSave(record, saved)
      } catch (e) { console.error(e) }
    },
    afterSave(initRec, savedRec) {
      savedRec.status = 'saved'
      this.qryRequest.columns.forEach(fld => {
        // console.log(`fld : ${fld.key}, ${fld.entitysid}`)
        if (fld.key !== 'actions') {
          initRec[fld.key].value = savedRec[fld.key].value
          initRec[fld.key].title = savedRec[fld.key].title
        }
      })
      initRec.status = 'saved'
    },
    createRequest() {
      const rec = this.getNewRecord()
      this.qryRequest.recordset.records.unshift(rec)
    },
    getNewRecord() {
      const n = {}
      this.qryRequest.columns.forEach(fld => {
        if (fld.default) {
          n[fld.key] = {}
          n[fld.key].value = fld.default.value
          n[fld.key].title = fld.default.title
        }
      })
      n[this.keyScenario].value = this.scenario[this.keyScenario].value
      n[this.keyScenario].title = this.scenario[this.keyScenario].title
      n[this.keyAuthorSid].value = this.session.user.sid
      n[this.keyAuthorSid].title = this.session.user.name
      n[this.keyCreated].value = useCubus.dateToString(new Date())
      n.cbs_access = 'rw'
      n.sub_recordsets = {}
      this.objRequest.children.forEach(c => {
        n.sub_recordsets[c.object.sid] = { records: [] }
      })
      return JSON.parse(JSON.stringify(n))
    },
    selectRec(rec) {
      if (rec.cbs_access === 'ro') return
      if (!rec.selected) this.$set(rec, 'selected', true)
      else rec.selected = !rec.selected
    },
    selected() {
      if (!this.qryRequest) return []
      return this.qryRequest.recordset.records.filter(r => r.selected)
    },
    deleteRecords() {
      // console.log('deleteRecs', this.selected())
      this.qryRequest.recordset.records.filter(r => r.selected).forEach(r => {
        this.deleteSubRecords(r)
        if (r.sid.value) {
          this.deleteRecord(this.objRequest.object.id, r, this.qryRequest.recordset.records)
        } else {
          this.qryRequest.recordset.records.splice(this.qryRequest.recordset.records.indexOf(r), 1)
        }
      })
    },
    async deleteRecord(objectid, record, records) {
      try {
        await useCubus.deleteRecord({ objectid, record })
        records.splice(this.qryRequest.recordset.records.indexOf(record), 1)
      } catch (e) { console.error(e) }
    },
    deleteSubRecords(record) {
      this.objRequest.children.forEach(c => {
        record.sub_recordsets[c.object.sid].records.forEach(r => {
          if (r.id.value) this.deleteRecord(c.object.id, r, record.sub_recordsets[c.object.sid].records)
        })
      })
    },
    onUpdateCell(rec, key, val) {
      // console.log(`onUpdateCell : key = ${key}, val = ${JSON.stringify(val)}`, rec)
      rec[key].value = val.value
      rec[key].title = val.title
      rec[key].status = 'changed'
      this.saveRecord(rec)
    },
    hasEmptyRecord() {
      if (!this.qryRequest) return true
      return this.qryRequest.recordset.records.some(r => r.id.value === null)
    },
    isAdmin() {
      return this.session.user.roles.some(r => r.sid === 'admin')
    },
    accessSid(rec) {
      const unitid = rec[this.keyUnit].value
      const accessRecord = this.qryAccess.recordset.records
        .some(acc => acc[this.keyUnit].value === unitid
            && acc[this.keyAccess].value === this.rwValue
            && this.session.user.roles.some(role => role.id === acc[this.keyRole].value))
      if (accessRecord || this.isAdmin()) return 'rw'
      return 'ro'
    },
    styleStick2() {
      return {
        left: `${this.stick()}px`,
      }
    },
    styleFlt2() {
      return {
        left: `${this.stick()}px`,
        top: `${this.stick_h1()}px`,
      }
    },
    styleFlt3() {
      return {
        top: `${this.stick_h1()}px`,
      }
    },
    styleFlt4() {
      return {
        left: `${this.stick2()}px`,
        top: `${this.stick_h1()}px`,
      }
    },
    getCondition(key) {
      const col = this.qryRequest.columns.find(c => c.key === key)
      if (!col) return null
      const cond = this.filter.node.nodes[0].conds.find(c => c.args[0].value === col.entityid)
      if (cond) return cond
      const newCond = this.getNewCondition(col)
      this.filter.node.nodes[0].conds.push(newCond)
      return newCond
    },
    getNewCondition(col) {
      const operSid = col.datatype === 'string' ? 'contain' : 'equal'
      return {
        isactive: false,
        oper: { sid: operSid },
        args: [
          { type: { sid: 'entity' }, value: col.entityid },
          { type: { sid: 'value' }, value: null },
        ],
      }
    },
    applyFilter() {
      this.loadQryRequest('filter')
    },
    getColumn(key) {
      return this.qryRequest.columns.find(c => c.key === key)
    },
    refPnl(rec) {
      // console.log('refPnl', rec)
      if (!rec) return null
      const budgetSectionId = rec[this.keyBudgetSection].value
      // console.log('budgetSectionId', budgetSectionId)
      // console.log('ref pnl', this.refs.pnlitem)
      if (this.refs.pnlitem) {
        return this.refs.pnlitem.filter(r => r.budget_section === budgetSectionId)
      }
      return []
    },
    refCapex(rec) {
      if (!rec) return null
      const budgetSectionId = rec[this.keyBudgetSection].value
      if (this.refs.capex_item) {
        return this.refs.capex_item.filter(r => r.budget_section === budgetSectionId)
      }
      return []
    },
  },
}
</script>

<style scoped>
.stick-th1 {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 3;
}
.stick-th2 {
  position: sticky;
  top: 0;
  z-index: 3;
}
.stick-th {
  position: sticky;
  top: 0;
  z-index: 2;
}
.stick-col1 {
  position: sticky;
  left: 0;
  z-index: 1;
}
.stick-col {
  position: sticky;
  z-index: 1;
}
.cell-val {
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
}
.cell-ref {
  padding: 0.3rem 0.1rem 0.3rem 0.1rem;
  border-left: none;
  border-right: none;
}
.ref-left {
  border-left: none;
}
.ref-right {
  border-right: none;
}
.cell {
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;
}
.flt0 {
  text-transform: none;
  font-weight: normal;
}
.flt1 {
  left: 0;
}
.flt {
  padding: 0.2rem;
  text-align: center;
  position: sticky;
  z-index: 3;
}
.flt2 {
  padding: 0.2rem;
  text-align: center;
  position: sticky;
  z-index: 2;
}
</style>
