<template>
  <div style="width: 100%;">
    <div class="cbs-dropdown" style="width: 100%;">
      <div v-if="!isEdit" @click="setEditOn" style="border: none; padding: 0;" :style="record.cbs_access === 'rw' ? 'cursor: pointer;' : ''">
        {{ title || '\u00a0' }}
      </div>

      <b-input-group v-if="isEdit" class="input-group-merge">
        <b-form-input v-model="work" :id="`input_${uuid}`" :placeholder="title" @blur="input_blur()" />
        <b-input-group-append is-text>
          <feather-icon icon="XIcon" class="cursor-pointer" @click="clearValue()" @mouseover="isMouseOnX = true" @mouseout="isMouseOnX = false" />
        </b-input-group-append>
      </b-input-group>

      <ul v-if="menuVisible"
          class="cbs-dropdown-menu"
          @click="menu_click()"
          @mouseover="isMouseOnMenu = true"
          @mouseout="isMouseOnMenu = false">
        <li v-for="el in options()" :key="el.value" style="padding: 0; border-bottom: solid #eeeeee 1px;">
          <div v-if="el.disabled" class="bg-secondary bg-lighten-5" style="padding: 0.5rem;">
            {{ el.title }}
          </div>
          <div v-else-if="record[field.key].value === el.value" class="bg-primary bg-lighten-5" style="padding: 0.5rem;">
            {{ el.title }}
          </div>
          <div v-else @click="selectElement(el)" style="cursor: pointer; padding: 0.5rem;">
            {{ el.title }}
          </div>
        </li>
      </ul>
      <div v-if="false">
        title: {{ title }}
      </div>
    </div>
    <cbs-debug v-if="false" :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import {
  BFormInput, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import useCubus from '@/cubus/services/useCubus'

export default {
  name: 'CbsReferencePicker3',
  components: {
    CbsDebug,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    field: {
      type: Object,
      default: null,
    },
    variant: {
      type: String,
      default: '',
    },
    record: {
      type: Object,
      default: null,
    },
    propRef: {
      type: Array,
      default: null,
    },
    noRefresh: {
      type: Boolean,
      default: false,
    },
    noLink: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['updateCell'],
  data() {
    return {
      menuVisible: false,
      title: null,
      value: null,
      isEdit: false,
      work: null,
      uuid: null,
      isMouseOnMenu: false,
      isMouseOnX: false,
      ref: null,
    }
  },
  watch: {
    record: {
      handler() {
        this.init()
      },
      deep: true,
    },
  },
  created() {
    this.uuid = useCubus.guid()
    this.init()
  },
  methods: {
    init() {
      this.title = this.record[this.field.key].title
      this.value = this.record[this.field.key].value
      if (!this.propRef) this.loadRef()
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible
      this.work = this.title
      this.isEdit = true
      // this.menuVisible = true
    },
    onBlur() {
      // console.log('onBlur')
      this.menuVisible = false
      this.isEdit = false
    },
    selectElement(el) {
      // console.log('selectElement', el)
      this.menuVisible = false
      this.isEdit = false
      this.isMouseOnX = false
      this.isMouseOnMenu = false
      this.$emit('updateCell', el)
    },
    onFocus() {
      // console.log('onfocus')
      this.menuVisible = true
    },
    setEditOn() {
      if (this.record.cbs_access === 'ro') return
      if (this.record.cbs_sys_access === 'ro') return
      this.menuVisible = true
      this.isEdit = true
      this.$nextTick(() => {
        document.getElementById(`input_${this.uuid}`).focus()
      })
    },
    setEditOff() {
      this.isEdit = false
    },
    menu_click() {
      // console.log('menu_click')
    },
    input_blur($event) {
      // console.log('input_blur')
      // console.log('event', $event)
      // this.menuVisible = false
      if (this.isMouseOnX) {
        this.clearValue()
      } else if (this.isMouseOnMenu) {
        // console.log('Mouse is On Menu')
      } else {
        this.isEdit = false
        this.menuVisible = false
        this.work = null
        // console.log('Mouse is out of Menu')
      }
    },
    options() {
      if (this.work) return (this.propRef || this.ref).filter(el => this.filterBy(el.title, this.work))
      return this.propRef
    },
    filterBy(title, search) {
      const pattern = `^${search
        .replace(new RegExp(' ', 'gi'), '.*')
        .replace(new RegExp('\\.\\*\\.\\*', 'gi'), ' ')}.*`
      const re = new RegExp(pattern, 'gi')
      return re.test(title || '')
    },
    clearValue() {
      this.title = null
      this.value = null
      this.isEdit = false
      this.menuVisible = false
      this.isMouseOnX = false
      this.isMouseOnMenu = false
      this.$emit('updateCell', { value: null, title: null })
    },
    async loadRef() {
      const ref = await useCubus.loadRef(this, { entityid: this.field.entityid })
      if (ref) {
        this.ref = ref
      }
    },
  },
}
</script>

<style scoped>
.cbs-dropdown {
  position: relative;
  display: inline-block;
}

.cbs-dropdown-button {
  background-color: #3498db;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.cbs-dropdown-button:hover {
  background-color: #2980b9;
}

.cbs-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 0;
  margin: 0;
  width: 200%;
  z-index: 999;
  overflow: scroll;
  max-height: 30vh;
}

.cbs-dropdown-menu li {
  padding: 0.5rem;
  text-align: left;
}

.cbs-dropdown-menu li:hover {
  background-color: #f1f1f1;
}
</style>
